/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

a {
  text-decoration: none;
  color: #003e7e;
}

/* Header styling */
header {
  background-color: #003e7e;
  padding: 20px;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

/* Navigation Menu */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 150px;
}

/* Ensure nav-links are displayed inline */
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px; /* Space between the navigation links */
  align-items: center; /* Ensures vertical alignment is consistent */
}

.nav-links li {
  display: inline-block;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  text-transform: capitalize;
  display: block; /* Ensures entire link area is clickable */
}

.nav-links .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.nav-links .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.nav-links .dropdown-content a:hover {
  background-color: #ddd;
}

.nav-links .dropdown:hover .dropdown-content {
  display: block;
}

.nav-links li a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.cta-btn {
  background-color: #ff6600;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: auto;
}

.cta-btn:hover {
  background-color: #cc5200;
  transform: scale(1.05);
}

/* Remove hamburger menu for desktop */
.hamburger {
  display: none;
}

/* Desktop Hero Section */
#hero {
  margin-top: 100px;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 20px;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 25%;
  margin-right: 20px;
}

.login-box h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #003e7e;
}

.input-container {
  margin-bottom: 15px;
}

.input-container label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #003e7e;
}

.input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input-container input:focus {
  border-color: #003e7e;
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.remember-me input {
  margin-right: 5px;
}

.remember-me label {
  font-size: 12px;
  color: #003e7e;
}

.login-box button {
  background-color: #003e7e;
  color: white;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-box button:hover {
  background-color: #002c5c;
}

.video-container {
  position: relative;
  width: 65%;
  margin-left: 20px;
}

#heroVideo {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  padding: 10px;
}

.overlay-text h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.overlay-text p {
  font-size: 14px;
}

.hero-btn {
  background-color: #ff6600;
  padding: 8px 20px;
  color: white;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-btn:hover {
  background-color: #cc5200;
  transform: scale(1.05);
}

.marketing-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  width: 100%;
  max-width: 1200px;
}

.icon-item {
  text-align: center;
  width: 30%;
}

.icon-item img {
  width: 60px;
  margin-bottom: 20px;
}

.icon-item h3 {
  font-size: 18px;
  color: #003e7e;
  margin-bottom: 10px;
}

.icon-item p {
  font-size: 14px;
  color: #333;
}

/* Key Features Section */
.features {
  padding: 60px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.feature-box {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.feature-item {
  width: 30%;
  padding: 20px;
  background-color: #e6f2ff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.feature-item h3 {
  color: #003e7e;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 14px;
  color: #333;
}

.solutions {
  padding: 60px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.solution-box {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.solution-item {
  width: 30%;
  padding: 20px;
  background-color: #e6f2ff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.solution-item h3 {
  color: #003e7e;
  margin-bottom: 10px;
}

.solution-item p {
  font-size: 14px;
  color: #333;
}

#get-started {
  background-color: #e6f2ff;
  text-align: center;
  padding: 40px 20px;
}

#get-started h2 {
  font-size: 36px;
  color: #003e7e;
}

#get-started p {
  font-size: 18px;
  margin: 20px 0;
}

#get-started .cta-btn {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #ff6600;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

#get-started .cta-btn:hover {
  background-color: #cc5200;
  transform: scale(1.05);
}

footer {
  background-color: #003e7e;
  color: white;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-footer img {
  width: 100px;
  height: auto;
}

.footer-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.footer-links li a {
  color: white;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  /* General adjustments */
  body {
    font-size: 14px;
    padding: 10px;
  }

  /* Header: Adjust spacing and mobile navigation */
  header {
    padding: 15px;
  }

  /* Mobile Menu */
  .nav-links {
    display: none;
    flex-direction: column;
    text-align: center;
    background-color: #003e7e; /* Same as header background color */
    position: absolute;
    top: 60px; /* Adjust based on header height */
    right: 0;
    width: 100%;
    z-index: 1; /* Ensure it appears above other content */
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin-bottom: 10px;
  }

  .nav-links .dropdown-content {
    position: static;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .hamburger .bar {
    height: 3px;
    width: 25px;
    background-color: white;
    margin: 4px 0;
    transition: 0.3s ease;
  }

  /* Hero Section */
  .hero-container {
    flex-direction: column;
    align-items: center;
  }

  .login-box,
  .video-container {
    width: 90%;
    margin-bottom: 20px;
  }

  /* Adjust button size for mobile */
  .cta-btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  /* Key Features and Solutions for Mobile */
  .features, .solutions {
    padding: 40px 10px;
    text-align: center;
  }

  .feature-box, .solution-box {
    flex-direction: column;
    align-items: center;
  }

  .feature-item, .solution-item {
    width: 100%;
    margin-bottom: 20px;
  }

  /* Footer: Adjust layout for mobile */
  footer {
    padding: 20px 10px;
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }
}

/* For even smaller screens */
@media (max-width: 480px) {
  /* Further adjustments for small screens */
  .cta-btn {
    padding: 8px 15px;
    font-size: 12px;
  }

  .hero-container {
    flex-direction: column;
  }

  .login-box, .video-container {
    width: 100%;
  }

  .icon-item img {
    width: 40px;
  }

  .icon-item h3 {
    font-size: 14px;
  }

  .feature-item, .solution-item {
    padding: 15px;
  }

  footer .footer-links {
    flex-direction: column;
    gap: 5px;
  }
}